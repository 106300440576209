// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_HugeNum = require("../Data.HugeNum/index.js");
var Data_Show = require("../Data.Show/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var React_Basic_Native_Styles_Text = require("../React.Basic.Native.Styles.Text/index.js");
var ZAP_Dart_Client_Components_Button = require("../ZAP.Dart.Client.Components.Button/index.js");
var ZAP_Dart_Client_Components_Typography = require("../ZAP.Dart.Client.Components.Typography/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var ZAP_Dart_Client_Styles_Fonts = require("../ZAP.Dart.Client.Styles.Fonts/index.js");
var ZAP_Dart_Client_Styles_Utils = require("../ZAP.Dart.Client.Styles.Utils/index.js");
var sheet = {
    container: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.backgroundColor(ZAP_Dart_Client_Styles_Colors.primary), React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(30), React_Basic_Native_Styles.paddingTop(React_Basic_Native_Styles.intPaddingTop)(8), React_Basic_Native_Styles.paddingBottom(React_Basic_Native_Styles.intPaddingBottom)(16) ]),
    infoContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.flexDirection(React_Basic_Native_Styles_Flex.row), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_Styles_Flex.spaceBetween), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)) ]),
    title: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_Styles_Text.left) ]),
    points: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_Styles_Text.right), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.manRope.bold) ]),
    button: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.marginTop(React_Basic_Native_Styles.intMarginTop)(16) ])
};
var component = React_Basic.createComponent("FooterButton");
var footerButton = (function () {
    var render = function (props) {
        return React_Basic_Native_Generated.safeAreaView()({
            children: Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_Native_Generated.view()({
                style: React_Basic_Native_Styles["styles'"]([ sheet.container, props.style ]),
                children: [ React_Basic_Native_Generated.view()({
                    style: sheet.infoContainer,
                    children: [ ZAP_Dart_Client_Components_Typography.b3({
                        style: sheet.title,
                        color: ZAP_Dart_Client_Styles_Colors.bg,
                        value: props.title
                    }), ZAP_Dart_Client_Components_Typography.b2({
                        style: sheet.points,
                        color: ZAP_Dart_Client_Styles_Colors.bg,
                        value: Data_Show.show(Data_Show.showNumber)(Data_HugeNum.toNumber(props.points)) + " PTS"
                    }) ]
                }), ZAP_Dart_Client_Components_Button.button({
                    title: props.btnText,
                    onPress: props.onPress,
                    disabled: props.disabled,
                    containerStyle: sheet.button,
                    titleStyle: ZAP_Dart_Client_Styles_Utils.noStyle,
                    size: ZAP_Dart_Client_Components_Button.lg,
                    color: ZAP_Dart_Client_Styles_Colors.primary,
                    backgroundColor: (function () {
                        if (props.disabled) {
                            return ZAP_Dart_Client_Styles_Colors.darkPrimary;
                        };
                        if (!props.disabled) {
                            return ZAP_Dart_Client_Styles_Colors.bg;
                        };
                        throw new Error("Failed pattern match at ZAP.Dart.Client.Components.FooterButton (line 71, column 23 - line 73, column 43): " + [ props.disabled.constructor.name ]);
                    })()
                }) ]
            }))
        });
    };
    return React_Basic.makeStateless(component)(render);
})();
module.exports = {
    footerButton: footerButton
};
