// Generated by purs version 0.13.8
"use strict";
var Data_DateTime = require("../Data.DateTime/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_PropTypes_Color = require("../React.Basic.Native.PropTypes.Color/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var React_Basic_Native_Styles_Text = require("../React.Basic.Native.Styles.Text/index.js");
var ReactNative_Image = require("../ReactNative.Image/index.js");
var ZAP_Dart_Client_Assets = require("../ZAP.Dart.Client.Assets/index.js");
var ZAP_Dart_Client_Components_Button = require("../ZAP.Dart.Client.Components.Button/index.js");
var ZAP_Dart_Client_Components_External_Grayscale = require("../ZAP.Dart.Client.Components.External.Grayscale/index.js");
var ZAP_Dart_Client_Components_Image = require("../ZAP.Dart.Client.Components.Image/index.js");
var ZAP_Dart_Client_Components_Typography = require("../ZAP.Dart.Client.Components.Typography/index.js");
var ZAP_Dart_Client_Screens_Utils = require("../ZAP.Dart.Client.Screens.Utils/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var ZAP_Dart_Client_Styles_Fonts = require("../ZAP.Dart.Client.Styles.Fonts/index.js");
var ZAP_Dart_Client_Styles_Utils = require("../ZAP.Dart.Client.Styles.Utils/index.js");
var sheet = {
    container: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles_Flex.flexDirection(React_Basic_Native_Styles_Flex.row) ]),
    leftSectionContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1) ]),
    rightSectionContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1) ]),
    imageContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles.paddingRight(React_Basic_Native_Styles.intPaddingRight)(6) ]),
    image: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.borderRadius(5), React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(100), React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)), React_Basic_Native_Styles.overflow(React_Basic_Native_PropTypes.hidden(React_Basic_Native_Styles.overflowV)) ]),
    infoContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_Styles_Flex.spaceBetween), React_Basic_Native_Styles.paddingLeft(React_Basic_Native_Styles.intPaddingLeft)(6) ]),
    vendorName: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.manRope.semiBold), React_Basic_Native_Styles.paddingBottom(React_Basic_Native_Styles.intPaddingBottom)(5) ]),
    itemName: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.manRope.light) ]),
    expiryLabel: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.manRope.semiBold), React_Basic_Native_Styles.paddingBottom(React_Basic_Native_Styles.intPaddingBottom)(5) ]),
    codeDisplayContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.backgroundColor(React_Basic_Native_PropTypes_Color.rgb(237)(237)(237)), React_Basic_Native_Styles.borderRadius(5), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles.marginRight(React_Basic_Native_Styles.numberMarginRight)(6.5) ]),
    codeDisplay: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)) ]),
    copyBtnContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles.marginLeft(React_Basic_Native_Styles.numberMarginLeft)(6.5) ]),
    copyBtnTitle: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontSize(12) ]),
    invalidMoreDetailsContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_Styles_Flex.flexStart(React_Basic_Native_Styles_Flex.alignmentStartEnd)) ]),
    invalidMoreDetailsButton: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.textDecorationLine(React_Basic_Native_Styles_Text.underline) ])
};
var component = React_Basic.createComponent("RewardItem");
var rewardItem = (function () {
    var rightSection = function (item) {
        return function (now) {
            return function (onMoreDetails) {
                return React_Basic_Native_Generated.view()({
                    style: sheet.rightSectionContainer,
                    children: [ React_Basic_Native_Generated.view()({
                        style: sheet.infoContainer,
                        children: [ React_Basic_Native_Generated.view_([ ZAP_Dart_Client_Components_Typography.b4({
                            style: sheet.vendorName,
                            color: ZAP_Dart_Client_Styles_Colors.altFg,
                            value: Data_String_Common.toUpper(Data_Maybe.fromMaybe("---")(item.vendorName))
                        }), ZAP_Dart_Client_Components_Typography.b3({
                            style: sheet.itemName,
                            color: ZAP_Dart_Client_Styles_Colors.altFg,
                            value: Data_Maybe.fromMaybe("---")(item.name)
                        }) ]), React_Basic_Native_Generated.view_([ ZAP_Dart_Client_Components_Typography.b3({
                            style: sheet.expiryLabel,
                            color: ZAP_Dart_Client_Screens_Utils.matchRewardExpiry(item)(now)({
                                valid: function (v) {
                                    return ZAP_Dart_Client_Styles_Colors.secondary;
                                },
                                expired: function (v) {
                                    return React_Basic_Native_PropTypes_Color.black;
                                },
                                redeemed: function (v) {
                                    return React_Basic_Native_PropTypes_Color.rgb(90)(166)(75);
                                },
                                "default": React_Basic_Native_PropTypes_Color.black
                            }),
                            value: ZAP_Dart_Client_Screens_Utils.matchRewardExpiry(item)(now)({
                                valid: function (expiry) {
                                    return "Valid until " + ZAP_Dart_Client_Screens_Utils.formatDate(Data_DateTime.date(expiry));
                                },
                                expired: function (expiry) {
                                    return "Expired on " + ZAP_Dart_Client_Screens_Utils.formatDate(Data_DateTime.date(expiry));
                                },
                                redeemed: function (redeemedAt) {
                                    return "Redeemed on " + ZAP_Dart_Client_Screens_Utils.formatDate(Data_DateTime.date(redeemedAt));
                                },
                                "default": ""
                            })
                        }), (function () {
                            var v = ZAP_Dart_Client_Screens_Utils.isRewardValid(item)(now);
                            if (v) {
                                return ZAP_Dart_Client_Components_Button.primaryButton({
                                    style: ZAP_Dart_Client_Styles_Utils.noStyle,
                                    title: "MORE DETAILS",
                                    onPress: onMoreDetails,
                                    disabled: false,
                                    inverted: false,
                                    size: ZAP_Dart_Client_Components_Button.xs
                                });
                            };
                            if (!v) {
                                return ZAP_Dart_Client_Components_Button.button({
                                    title: "MORE DETAILS",
                                    onPress: onMoreDetails,
                                    disabled: false,
                                    containerStyle: sheet.invalidMoreDetailsContainer,
                                    titleStyle: sheet.invalidMoreDetailsButton,
                                    size: ZAP_Dart_Client_Components_Button.xs,
                                    color: ZAP_Dart_Client_Styles_Colors.primary,
                                    backgroundColor: React_Basic_Native_PropTypes_Color.transparent
                                });
                            };
                            throw new Error("Failed pattern match at ZAP.Dart.Client.Components.RewardItem (line 114, column 19 - line 132, column 24): " + [ v.constructor.name ]);
                        })() ]) ]
                    }) ]
                });
            };
        };
    };
    var rewardImage = function (item) {
        return function (now) {
            return ZAP_Dart_Client_Components_Image.image({
                source: Data_Maybe.fromMaybe(ZAP_Dart_Client_Assets.defaultRewardItem)(Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(item.photoUri)(ReactNative_Image.imageUri)),
                style: React_Basic_Native_Styles["styles'"]([ sheet.image, React_Basic_Native_Styles.styles([ React_Basic_Native_Styles.opacity((function () {
                    var $7 = ZAP_Dart_Client_Screens_Utils.isRewardValid(item)(now);
                    if ($7) {
                        return 1.0;
                    };
                    return 0.5;
                })()) ]) ]),
                resizeMode: React_Basic_Native_Styles.cover
            });
        };
    };
    var leftSection = function (item) {
        return function (now) {
            return React_Basic_Native_Generated.view()({
                style: sheet.leftSectionContainer,
                children: [ React_Basic_Native_Generated.view()({
                    style: sheet.imageContainer,
                    children: [ (function () {
                        var v = ZAP_Dart_Client_Screens_Utils.isRewardValid(item)(now);
                        if (v) {
                            return rewardImage(item)(now);
                        };
                        if (!v) {
                            return ZAP_Dart_Client_Components_External_Grayscale.grayscale(rewardImage(item)(now));
                        };
                        throw new Error("Failed pattern match at ZAP.Dart.Client.Components.RewardItem (line 72, column 17 - line 74, column 60): " + [ v.constructor.name ]);
                    })() ]
                }) ]
            });
        };
    };
    var render = function (v) {
        return React_Basic_Native_Generated.view()({
            style: sheet.container,
            children: [ leftSection(v.rewardItem)(v.now), rightSection(v.rewardItem)(v.now)(v.onMoreDetails) ]
        });
    };
    return React_Basic.makeStateless(component)(render);
})();
module.exports = {
    rewardItem: rewardItem
};
